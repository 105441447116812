import React from "react";
import { Link } from "react-router-dom";
import eagle from "./Images/eagle.png";
import img1 from "./Images/img1.jpeg";
import img2 from "./Images/img21.jpeg";
import img3 from "./Images/img3.jpeg";
import logo from "./Images/right_logo.png";
import library from "../src/Images/library.png";

function Contact() {
  return (
    <div className="contact">
      <div className="head1 contact_nav">
        <img src={eagle} alt="Left Logo" id="eagle" />
        <div className="headerContent">
          <label className="bom_nrth">
            Bombay North RT - 19 <br /> Initiative
          </label>
          <h1 className="book_pros">"BOOKS FOR PROSPERITY 3.0"</h1>
        </div>
        <img src={logo} alt="Right Logo" id="logo" />
      </div>
      <div className="Main_Page">
        <div className="Main_inner2 mic">
          <img src={library} alt="library" className="Main_inner2-imgBg"></img>
        </div>
      </div>
      <div className="imageContainer">
        <img src={img1} alt="Left Logo" id="img1" />
        <img src={img3} alt="Left Logo" id="img3" />
        <img src={img2} alt="Left Logo" id="img2" />
      </div>
      <div className="head2">
        <h3 className="mb-0">Share The Gift Of knowledge</h3>
        <label className="head2_coll">Collection Centres</label>
        <h3>
          <u>Day Of Collection : 15th Sept 2022 to 15th Oct 2022 at Centres</u>
        </h3>
      </div>
      <div className="table2">
        <ul className="table2_ul">
          <li>Paras Jain</li>
          <li>
            <span className="mob">Area : </span>Marine Drive
          </li>
          <li>
            <span className="mob">Mobile : </span>
            <a href="https://wa.me/+919320140849" target="_blank">
              9320140849
            </a>
          </li>
        </ul>
        <ul className="table2_ul">
          <li>Animesh Damani</li>
          <li>
            <span className="mob">Area : </span>Malbar Hill
          </li>
          <li>
            <span className="mob">Mobile : </span>
            <a href="https://wa.me/+919930837462" target="_blank">
              9930837462
            </a>
          </li>
        </ul>
        <ul className="table2_ul">
          <li>Suparna Kabra</li>
          <li>
            <span className="mob">Area : </span>Worli
          </li>
          <li>
            <span className="mob">Mobile : </span>
            <a href="https://wa.me/+919892713578" target="_blank">
              9892713578
            </a>
          </li>
        </ul>
        <ul className="table2_ul">
          <li>Kamlesh Jain</li>
          <li>
            <span className="mob">Area : </span>Worli
          </li>
          <li>
            <span className="mob">Mobile : </span>
            <a href="https://wa.me/+919004000136" target="_blank">
              9004000136
            </a>
          </li>
        </ul>
        <ul className="table2_ul">
          <li>Piyush Khemka</li>
          <li>
            <span className="mob">Area : </span>Worli Naka
          </li>
          <li>
            <span className="mob">Mobile : </span>
            <a href="https://wa.me/+918454087700" target="_blank">
              8454087700
            </a>
          </li>
        </ul>
        <ul className="table2_ul">
          <li>Priyank Ranka</li>
          <li>
            <span className="mob">Area : </span>Byculla & Lower Parel
          </li>
          <li>
            <span className="mob">Mobile : </span>
            <a href="https://wa.me/+919819312721" target="_blank">
              9819312721
            </a>
          </li>
        </ul>
        <ul className="table2_ul">
          <li>Peony Rajesh Jain</li>
          <li>
            <span className="mob">Area : </span>Byculla
          </li>
          <li>
            <span className="mob">Mobile : </span>
            <a href="https://wa.me/+917506844590" target="_blank">
              7506844590
            </a>
          </li>
        </ul>
        <ul className="table2_ul">
          <li>Shrivardhan Bang</li>
          <li>
            <span className="mob">Area : </span>Dadar
          </li>
          <li>
            <span className="mob">Mobile : </span>
            <a href="https://wa.me/+917506773784" target="_blank">
              7506773784
            </a>
          </li>
        </ul>
        <ul className="table2_ul">
          <li>Ankit Jain</li>
          <li>
            <span className="mob">Area : </span>Kandivali & Borivali East
          </li>
          <li>
            <span className="mob">Mobile : </span>
            <a href="https://wa.me/+919699680721" target="_blank">
              9699680721
            </a>
          </li>
        </ul>
        <ul className="table2_ul">
          <li>Vinay Maheshwari</li>
          <li>
            <span className="mob">Area : </span>Ghatkopar East
          </li>
          <li>
            <span className="mob">Mobile : </span>
            <a href="https://wa.me/+919833941339" target="_blank">
              9833941339
            </a>
          </li>
        </ul>
        <ul className="table2_ul">
          <li>Priya Mehta</li>
          <li>
            <span className="mob">Area : </span>Parel
          </li>
          <li>
            <span className="mob">Mobile : </span>
            <a href="https://wa.me/+919867186600" target="_blank">
              9867186600
            </a>
          </li>
        </ul>
        <ul className="table2_ul">
          <li>Sneha Doshi</li>
          <li>
            <span className="mob">Area : </span>Ville Parle
          </li>
          <li>
            <span className="mob">Mobile : </span>
            <a href="https://wa.me/+919820752341" target="_blank">
              9820752341
            </a>
          </li>
        </ul>
        <ul className="table2_ul">
          <li>Matru Pitru Ashish Library</li>
          <li>
            <span className="mob">Area : </span>Kalbadevi Road
          </li>
          <li>
            <span className="mob">Mobile : </span>
            <a href="https://wa.me/+919820521210" target="_blank">
              9820521210
            </a>
          </li>
        </ul>
        <ul className="table2_ul">
          <li>Dr. Shetty</li>
          <li>
            <span className="mob">Area : </span>Wadala East
          </li>
          <li>
            <span className="mob">Mobile : </span>
            <a href="https://wa.me/+919821438664" target="_blank">
              9821438664
            </a>
          </li>
        </ul>
        <ul className="table2_ul">
          <li>Pragati Group</li>
          <li>
            <span className="mob">Area : </span>Mulund
          </li>
          <li>
            <span className="mob">Mobile : </span>
            <a href="https://wa.me/+919022289592" target="_blank">
              9022289592
            </a>
          </li>
        </ul>
        <ul className="table2_ul">
          <li>Varsha Agarwal</li>
          <li>
            <span className="mob">Area : </span>Vashi
          </li>
          <li>
            <span className="mob">Mobile : </span>
            <a href="https://wa.me/+919867390826" target="_blank">
              9867390826
            </a>
          </li>
        </ul>
        <ul className="table2_ul">
          <li>Anand Duggar</li>
          <li>
            <span className="mob">Area : </span>Pydhonie & CP Tank
          </li>
          <li>
            <span className="mob">Mobile : </span>
            <a href="https://wa.me/+919819116686" target="_blank">
              9819116686
            </a>
          </li>
        </ul>
        <ul className="table2_ul">
          <li>Komal Bajaj</li>
          <li>
            <span className="mob">Area : </span>Juhu-Scheme
          </li>
          <li>
            <span className="mob">Mobile : </span>
            <a href="https://wa.me/+919821989777" target="_blank">
              9821989777
            </a>
          </li>
        </ul>
        <ul className="table2_ul">
          <li>Kedar Mistry</li>
          <li>
            <span className="mob">Area : </span>Ville Parle
          </li>
          <li>
            <span className="mob">Mobile : </span>
            <a href="https://wa.me/+919833977798" target="_blank">
              9833977798
            </a>
          </li>
        </ul>
        <ul className="table2_ul">
          <li>Raghav Somani</li>
          <li>
            <span className="mob">Area : </span>Andheri West
          </li>
          <li>
            <span className="mob">Mobile : </span>
            <a href="https://wa.me/+919930916988" target="_blank">
              9930916988
            </a>
          </li>
        </ul>
        <ul className="table2_ul">
          <li>Rahul Rathi</li>
          <li>
            <span className="mob">Area : </span>Malad West
          </li>
          <li>
            <span className="mob">Mobile : </span>
            <a href="https://wa.me/+919769830862" target="_blank">
              9769830862
            </a>
          </li>
        </ul>
        <ul className="table2_ul">
          <li>Chandrakant Shah</li>
          <li>
            <span className="mob">Area : </span>Fort
          </li>
          <li>
            <span className="mob">Mobile : </span>
            <a href="https://wa.me/+919820013412" target="_blank">
              9820013412
            </a>
          </li>
        </ul>
        <ul className="table2_ul">
          <li>Aarti Jumani</li>
          <li>
            <span className="mob">Area : </span>Khar & Santacruz
          </li>
          <li>
            <span className="mob">Mobile : </span>
            <a href="https://wa.me/+919820211597" target="_blank">
              9820211597
            </a>
          </li>
        </ul>
        <ul className="table2_ul">
          <li>Priyank Agarwal</li>
          <li>
            <span className="mob">Area : </span>Thane East & West
          </li>
          <li>
            <span className="mob">Mobile : </span>
            <a href="https://wa.me/+919321324242" target="_blank">
              9321324242
            </a>
          </li>
        </ul>
        <div className="lead-div">
          <span className="lead">BRT - 19 Secretary : </span>
          <span className="lead_name">Tr. Kedar Mistry</span>
          <br />
          <span className="lead">Project Convener : </span>
          <span className="lead_name">Tr. Paras Jain</span>
        </div>
      </div>
    </div>
  );
}

export default Contact;
