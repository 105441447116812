import React from "react";
import { Link } from "react-router-dom";
import eagle from "./Images/eagle.png";
import logo from "./Images/right_logo.png";
import Book1 from "./Images/book1.png";
import Book2 from "./Images/book2.png";
import library from "../src/Images/library.png";

function Book() {
  return (
    <div>
      <div className="head1 book_nav">
        <img src={eagle} alt="Left Logo" id="eagle" />
        <div>
          <label className="bom_nrth">Bombay North RT - 19 Initiative</label>
          <h1 className="book_pros">"BOOKS FOR PROSPERITY 3.0"</h1>
        </div>
        <img src={logo} alt="Right Logo" id="logo" />
      </div>
      <div className="Main_Page">
        <div className="Main_inner2 mib">
          <img src={library} alt="library" className="Main_inner2-img"></img>
        </div>
        <div className="Main_inner1">
          <label className="hindi_text1">
            चलो बढ़ाए कदम शिक्षा की ओर, फैलाये ज्ञान का उजाला चारो ओर
          </label>
          <label className="label_txt">
            Come, let's join hands in gifting knowlege to the underprivileged.
            Lets create libraries that empowers and gifts the needy the right to
            learn. Donate books that you might not need but can light a lamp in
            someone's life.
          </label>
          <div className="book_grid">
            <img src={Book1} alt="Left Book" id="book1" />
            <div>
              <label className="start_proj">starting our project</label>
              <h1 className="book_pros2">BOOKS FOR PROSPERITY 3.0</h1>
            </div>
            <img src={Book2} alt="Right Book" id="book2" />
          </div>
          <label className="label_txt">
            You may contribute only below books 📚 for children from 2 to 15
            years
          </label>
          <div className="table1">
            <div className="col-6">
              <ul>
                <li className="table1_li">Story books</li>
                <li className="table1_li">Pictorial books</li>
                <li className="table1_li">Motivational</li>
                <li className="table1_li">Sports</li>
              </ul>
            </div>
            <div className="col-6">
              <ul>
                <li className="table1_li">Science</li>
                <li className="table1_li">History</li>
                <li className="table1_li">Geography</li>
                <li className="table1_li">General knowledge</li>
              </ul>
            </div>
          </div>
          <label className="label_txt mb-0">
            In Any language (Hindi, Marathi, English or any Regional language)
          </label>
          <br />
          <label className="label_txt mb-3">
            school syllabus books (Class 1 to 10)
          </label>
          <br />
          <label className="start_proj">
            <u>Books should be in good condition.</u>
          </label>
          <h1 className="book_pros2 mb-3 pt-5">
            शिक्षित करने की एक नयी राह, ज्ञान से सुनहरे भविष्य का निर्माण !
          </h1>

          <p className="query">
            For any enquiries please {/* <span className="click"> */}
            <a href="/contact-list" className="click">
              Click here
            </a>
            {/* </span> */}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Book;
