import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import Book from "./Book";
import Contact from "./contact";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/books-for-prosperity" component={Book} />
          <Route exact path="/contact-list" component={Contact} />
          <Redirect from="/" to="/books-for-prosperity" />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
